<template>
  <div id="school-standards">
      <b-container class="base-container-x">
        <div v-if="loading === true" class="col-12">
          <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
        </div>
        <div v-if="loading === false" id="banner" class="banner-inner avs">
          <!-- <div class="slideshow_caption">
            <div class="slideshow_inner_caption">
              <div class="slideshow_align_caption">
                <h1 class="section-header-title text-uppercase">{{ $t('EYEP') }}</h1>
                <h3 class="article-content">{{ $t('Exchange Yoga Experience Program (WYA Continuous Yoga Education)') }}</h3>
              </div>
            </div>
          </div> -->
        </div>
        <div v-if="loading === true" class="col-12 mt-4">
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
          <br/>
          <b-skeleton animation="fade" width="85%"></b-skeleton>
          <b-skeleton animation="fade" width="55%"></b-skeleton>
          <b-skeleton animation="fade" width="70%"></b-skeleton>
        </div>
        <div v-if="loading === false" class="col-12 mt-4 mb-5 pt-4">
          <div  class="d-none d-lg-flex d-xl-flex justify-content-center align-items-center">
            <h2 class="text-center font-weight-bold">{{ $t('Ayurveda School – AVS') }}</h2>
            <b-img width="100%" class="ml-5" :src="PUBLIC_PATH+'img/certificate/AVS.png'"/>
          </div>
          <div class="d-block d-lg-none d-xl-none">
            <h2 class="text-center font-weight-bold">{{ $t('Ayurveda School – AVS') }}</h2>
            <div class="d-flex justify-content-center align-items-center">
              <b-img width="100%" class="mt-3" :src="PUBLIC_PATH+'img/certificate/AVS.png'"/>
            </div>
          </div>
          <h5 class="mt-5">{{ $t('Training Hour Standards & Education Levels:') }}</h5>
          <b-table-simple caption-top responsive bordered class="table-default mt-3 levels-table">
            <b-thead>
              <b-tr>
              <b-th variant="light" >{{ $t('Additional') }}</b-th>
              <b-th variant="light" >{{ $t('Level 1')}} </b-th>
              <b-th variant="light" >{{ $t('Level 2')}} </b-th>
              <b-th variant="light" >{{ $t('Level 3')}} </b-th>
              <b-th variant="light" >{{ $t('Level 4')}} </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
              <b-td @click="scrollToElement('under-50-hours-training-registration')">{{ $t('50 Hours') }}</b-td>
              <b-td @click="scrollToElement('200-hours-training-registration')">{{ $t('200 Hours') }}</b-td>
              <b-td @click="scrollToElement('300-hours-training-registration')">{{ $t('300 Hours') }}</b-td>
              <b-td @click="scrollToElement('500-hours-training-registration')">{{ $t('500 Hours') }}</b-td>
              <b-td @click="scrollToElement('1000-hours-training-registration')" rowspan="2" >{{ $t('1000 Hours') }}</b-td>
              </b-tr>
              <b-tr>
              <b-td @click="scrollToElement('100-hours-training-registrantion')">{{ $t('100 Hours') }}</b-td>
              <b-td @click="scrollToElement('250-hour-training-registration')">{{ $t('250 Hours') }}</b-td>
              <b-td @click="scrollToElement('400-hours-training-registration')">{{ $t('400 Hours') }}</b-td>
              <b-td @click="scrollToElement('600-hours-training-registration')">{{ $t('600 Hours') }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <div class="introduction">
            <h3 class="mt-5 mb-3">{{ $t('Introduction:') }}</h3>
            <p>{{ $t('World Yoga Alliance values quality over quantity. This is why our trainings are not only divided into Hours but also into levels. Each level has different syllabus and curriculum standards set by WYA Organization. If you are looking for Ayurveda school registration, we are waiting for you to join the community. Please select your registration type.') }} {{ $t('If you need assistance, please feel free to') }} <router-link :to="{ name: 'contact' }">{{  $t('contact us')}}.</router-link></p>
            <p><strong><u>{{ $t('Registration type similar as:') }}</u></strong> {{ $t('Ayurveda School / School of Ayurveda.') }}</p>
            <ul>
              <li @click="scrollToElement('under-50-hours-training-registration')"><strong>{{ $t('Under 50 Hours Training Registration:') }}</strong></li>
            </ul>
            <p>{{ $t('This registration type is good for starters who love working with a small community and would like to provide high quality courses with WYA.') }} <a href="javascript:void(null)" @click="scrollToElement('under-50-hours-training-registration')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('100-hours-training-registrantion')"><strong>{{ $t('100 Hours Training Registration:') }}</strong></li>
            </ul>
            <p>{{ $t('This is a primary beginner level Ayurveda training for everyone who wants to learn and practice Ayurveda from the foundation.') }} <a href="javascript:void(null)" @click="scrollToElement('100-hours-training-registrantion')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('200-hours-training-registration')"><strong>{{ $t('200 Hours Training Registration') }}</strong></li>
            </ul>
            <p>{{ $t('You can registrar your school under this course’s standards if you are providing Ayurveda teacher training at Level 1.') }} <a href="javascript:void(null)" @click="scrollToElement('200-hours-training-registration')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('250-hour-training-registration')"><strong>{{ $t('250 Hours Training Registration') }}</strong></li>
            </ul>
            <p>{{ $t('You can registrar your school under this course’s standards if you are providing Ayurveda teacher training at Level 1.') }} <a href="javascript:void(null)" @click="scrollToElement('250-hour-training-registration')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('300-hours-training-registration')"><strong>{{ $t('300 Hours Training Registration') }}</strong></li>
            </ul>
            <p>{{ $t('Upon successful register as 200-hours Ayurveda school license you can registrar your school under this course’s standards if you are providing Ayurveda teacher training as Level 2.') }}  <a href="javascript:void(null)" @click="scrollToElement('300-hours-training-registration')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('400-hours-training-registration')"><strong>{{ $t('400 Hours Training Registration') }}</strong></li>
            </ul>
            <p>{{ $t('Upon successful register as 250-hours Ayurveda school license you can registrar your school under this course’s standards if you are providing Ayurveda teacher training as Level 2.') }} <a href="javascript:void(null)" @click="scrollToElement('400-hours-training-registration')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('500-hours-training-registration')"><strong>{{ $t('500 Hours Training Registration') }}</strong></li>
            </ul>
            <p>{{ $t('Upon successful register as 200-hours / 300-hours Ayurveda school license you can registrar your school under this course’s standards if you are providing Ayurveda teacher training as Level 3.') }} <a href="javascript:void(null)" @click="scrollToElement('500-hours-training-registration')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('600-hours-training-registration')"><strong>{{ $t('600 Hours Training Registration') }}</strong></li>
            </ul>
            <p>{{ $t('Upon successful register as 250-hours / 400-hours Ayurveda school license you can registrar your school under this course’s standards if you are providing Ayurveda teacher training as Level 3.') }} <a href="javascript:void(null)" @click="scrollToElement('600-hours-training-registration')">{{ $t('Read More...') }}</a></p>
            <ul>
              <li @click="scrollToElement('1000-hours-training-registration')"><strong>{{ $t('1000 Hours Training Registration') }}</strong></li>
            </ul>
            <p>{{ $t('Upon successful register as 500-hours / 600-hours Ayurveda school license you can registrar your school under this course’s standards if you are providing Ayurveda teacher training as Level 4.') }} <a href="javascript:void(null)" @click="scrollToElement('1000-hours-training-registration')">{{ $t('Read More...') }}</a></p>
          </div>
          <div id="under-50-hours-training-registration" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('Under 50 Hours training level:') }}</h4>
            <p>{{ $t('This type of Ayurveda teacher training is an additional Certification Course provided by WYA Community Ayurveda Schools worldwide. This course is for all those who wish to follow exclusive teacher training in Ayurveda and health.') }}</p>
            <p>{{ $t('WYA 50 Hours Ayurveda teacher training framework registration carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga and Ayurveda community. We set all of the following health and safety standards in order to provide the best experience for your students.') }}</p>
            <p>{{ $t('Please note, this is a very specific course intended for all those who have already completed any of 200 Hours Ayurveda Teacher Training by any WYA authorized school, and would like to become a specialist in Ayurveda and health education.') }}</p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('Everyone.') }}</p>
            <p>{{ $t('Duration: Total 5 – 6 days regular training. However, it depends on the Schools or course timetable please check with school.') }}</p>
            <p><strong>{{ $t(' Training Standards for 50 Hours Ayurveda TTC Providers:') }}</strong></p>
            <ul><li>{{ $t('Ayurveda Practical Techniques (Total 25 hours with 21 contact hours) This training includes the techniques of physical and mental level:') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                <b-td >{{ $t('The Fundamental Principles of Ayurveda') }}</b-td>
                <b-td >{{ $t('13 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                <b-td >{{ $t('Self Healing Methodology') }}</b-td>
                <b-td >{{ $t('8 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Ayurveda Theoretical (total 25 hour with 20 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                <b-td >{{ $t('Ayurveda Energy Systems') }}</b-td>
                <b-td >{{ $t('4 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                <b-td >{{ $t('Theory of Diet & Optimal Health') }}</b-td>
                <b-td >{{ $t('16 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 9 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of Ayurveda theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
          <div id="100-hours-training-registrantion" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('100 Hours training level:') }}</h4>
            <p>{{ $t('WYA 100 Hours Ayurveda teacher training framework registration carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga and Ayurveda community. We set all of the following health and safety standards in order to provide the best experience for your students.') }}</p>
            <p>{{ $t('100 Hours Ayurveda Teacher Training is a standard training set forth by the World Yoga Alliance for all training organizers. This training is based on 200 Hours Ayurveda teacher training, but is conveniently divided into two sections, each of 100 hours. This is a primary beginner level Ayurveda training for everyone who wants to learn and practice Ayurveda. It’s very important that your training is done and completed with the same Ayurveda school/teacher, so you can receive a diploma after each completed 100 Hours training section. When you fully complete your 200 Hours Ayurveda Teacher Training Course (2 separate courses), you can register with World Yoga Alliance to receive a 200 Hours Diploma and an official Ayurveda teacher license.') }}</p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('Everyone.') }}</p>
            <p>{{ $t('Duration: Total 10 – 11 days regular training. However, it depends on the Schools or course timetable please check with school.') }}</p>
            <p><strong>{{ $t('Training Standards for 100 Hours Ayurveda TTC Providers:') }}</strong></p>
            <ul><li>{{ $t('Ayurveda Practical Techniques (total 58 hours with 46 contact hours)') }}</li></ul>
            <p>{{ $t('This training including the techniques of physical and mental level:') }}</p>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                <b-th class="w-25" variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                <b-td >{{ $t('The Fundamental Principles of Ayurveda') }}</b-td>
                <b-td >{{ $t('30 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                <b-td >{{ $t('Self Healing Methodology') }}</b-td>
                <b-td >{{ $t('10 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                <b-td >{{ $t('Practical Teaching & Workshop:') }}</b-td>
                <b-td >{{ $t('6 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Ayurveda Theoretical (total 42 hour with 34 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                <b-td >{{ $t('Ayurveda Energy Systems') }}</b-td>
                <b-td >{{ $t('14 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                <b-td >{{ $t('Theory of Diet & Optimal Health') }}</b-td>
                <b-td >{{ $t('12 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                <b-td >{{ $t('8 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 20 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of Ayurveda theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
          <div id="200-hours-training-registration" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('200 Hours training level 1:') }}</h4>
            <p>{{ $t('WYA 200 Hours Ayurveda teacher training framework registration carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga and Ayurveda community. We set all of the following health and safety standards in order to provide the best experience for your students.') }}</p>
            <p>{{ $t('200 Hours Ayurveda Teacher Training is a standard Level 1 training course designed for everyone, regardless of age and previous Ayurveda experience, who would like to learn and master the basics and principles of Ayurveda practice. This training standard is set forth by the World Yoga Alliance Organization to provide most valuable Ayurveda knowledge, principles, and ethical guidelines to all Ayurveda practitioners around the world. We present this standard to all our TTC provider members to follow and structure their courses, making sure that WYA education quality is well maintained across the world.') }}</p>
            <p>{{ $t('The structure of the 200 Hours Ayurveda TTC allows for all those who have never practiced Ayurveda before to easily follow the teachings, and for those who already have some experience to deepen and broaden their knowledge. We believe in inner balance and emotional harmony, and our goal is to give you a transformational experience, regardless of who you are, that you can then apply to your Ayurveda practice and teachings. ') }}</p>
            <p>{{ $t('WYA Ayurveda Teacher Training Course Standards include both theoretical and practical techniques, as well as homework to make sure you are ready to become a professional Ayurveda teacher or school. Each student who successfully completes the 200 Hours Ayurveda teaching training may receive an official WYA Course Diploma including teacher ID Number, enjoying various benefits provided by WYA Community.') }} {{ $t('Please check our ') }} <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards here.') }}</router-link></p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('Everyone.') }}</p>
            <p>{{ $t('Duration: Total 21 – 30 days regular training. However, it depends on the Schools or course timetable please check with school.') }}</p>
            <p><strong>{{ $t('Training Standards for 200 Hours Ayurveda TTC Providers:') }}</strong></p>
            <ul><li>{{ $t('Ayurveda Practical Techniques (total 119 hours with 94 contact hours)') }}</li></ul>
            <p>{{ $t('This training includes the techniques of physical and mental level:') }}</p>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('The Fundamental Principles of Ayurveda') }}</b-td>
                  <b-td >{{ $t('60 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Self Healing Methodology') }}</b-td>
                  <b-td >{{ $t('17 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Business Plan - Basic knowledge') }}</b-td>
                  <b-td >{{ $t('6 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Group Meeting / Teaching Practice:') }}</b-td>
                  <b-td >{{ $t('6 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Teaching practical & Workshop ') }}</b-td>
                  <b-td >{{ $t('5 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Yoga Theoretical (total 81 hours with 56 contact hours)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-tbody>
                <b-tr>
                  <b-td class="w-75">{{ $t('Ayurveda Energy Systems') }}</b-td>
                  <b-td >{{ $t('25 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Theory of Diet & Optimal Health etc.') }}</b-td>
                  <b-td >{{ $t('15 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                  <b-td >{{ $t('16 Hours ') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 50 non-contact hours) ') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of Ayurveda theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
          <div id="250-hour-training-registration" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('250 Hours training level 1:') }}</h4>
            <p>{{ $t('WYA 250 Hours Ayurveda teacher training framework registration carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga and Ayurveda community. We set all of the following health and safety standards in order to provide the best experience for your students.') }}</p>
            <p>{{ $t('250 Hours Ayurveda Teacher Training is a standard Level 1 Ayurveda training course designed for everyone, regardless of age and previous Ayurveda experience, who would like to learn and master the basics and principles of Ayurveda practice. This training standard is set forth by the World Yoga Alliance Organization to provide most valuable Ayurveda knowledge, principles, and ethical guidelines to all Ayurveda practitioners around the world. We present this standard to all our TTC provider members to follow and structure their courses, making sure that WYA education quality is well maintained across the world.') }}</p>
            <p>{{ $t('The structure of the 250 Hours Ayurveda TTC allows for all those who have never practiced Ayurveda before to easily follow the teachings, and for those who already have some experience to deepen and broaden their knowledge. We believe in inner balance and emotional harmony, and our goal is to give you a transformational experience, regardless of who you are, that you can then apply to your Ayurveda practice and teachings.') }}</p>
            <p>{{ $t('WYA Ayurveda Teacher Training Course Standards include both theoretical and practical techniques, as well as homework to make sure you are ready to become a professional Ayurveda teacher or school. Each student who successfully completes the 250 Hours Ayurveda teaching training may receive an official WYA Course Diploma including teacher ID Number, enjoying various benefits provided by WYA Community.') }} {{ $t('Please check our ') }} <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards here.') }}</router-link></p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('Everyone.') }}</p>
            <p>{{ $t('Duration: Total 26 – 35 days regular training. However, it depends on the Schools or course timetable please check with school.') }}</p>
            <p><strong>{{ $t('Training Standards for 250 Hours Ayurveda TTC Providers:') }}</strong></p>
            <ul><li>{{ $t('Ayurveda Practical Techniques (total 141 hours with 113 contact hours)') }}</li></ul>
            <p>{{ $t('This training includes the techniques of physical and mental level:') }}</p>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('The Fundamental Principles of Ayurveda') }}</b-td>
                  <b-td >{{ $t('60 Hours ') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Self Healing Methodology') }}</b-td>
                  <b-td >{{ $t('17 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Business Plan - Basic knowledge') }}</b-td>
                  <b-td >{{ $t('10 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Group Meeting / Teaching Practice:') }}</b-td>
                  <b-td >{{ $t('10 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Teaching practical & Workshop') }}</b-td>
                  <b-td >{{ $t('16 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Ayurveda Theoretical (total 109 hours with 87 contact hours)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-tbody>
                <b-tr>
                  <b-td class="w-75" >{{ $t('Ayurveda Energy Systems') }}</b-td>
                  <b-td >{{ $t('33 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Theory of Diet & Optimal Health etc.') }}</b-td>
                  <b-td >{{ $t('28 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                  <b-td >{{ $t('26 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 50 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of Ayurveda theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
           <div id="300-hours-training-registration" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('300 Hours training level 2:') }}</h4>
            <p>{{ $t('WYA 300 Hours Ayurveda teacher training framework registration carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga and Ayurveda community. We set all of the following health and safety standards in order to provide the best experience for your students.') }}</p>
            <p>{{ $t('This 300 Hours Ayurveda Teacher Training is a Level 2 that follows further the syllabus and structure of the 200 Hours level 1 Ayurveda TTC. This level is designed for all those who have already completed the 200 Hours Ayurveda Teacher Training and would like to deepen and broaden their knowledge, becoming professional teachers with the ability to become WYA Certified Ayurveda Trainers. If applicant haven’t graduated from a WYA 200 Hours Ayurveda TTC School, need to pass the orientation test to ensure that applicant have sufficient basic skills and knowledge to undertake further advanced course and continue the program. We recommend all WYA registrar and certified schools to require applicants of the 200 Hour Ayurveda TTC to pass the orientation test, specifically if the applicant has not previously completed a 200 Hour TTC through WYA.') }}</p>
            <p>{{ $t('All those who already have the 200 Hours level one Ayurveda TTC WYA Certificate with valid teacher ID, upon completion, will be able to receive a 500 Hours Ayurveda Teacher Training Diploma with World Yoga Alliance. If student had done 200-hour course with non WYA School, then school can provide only 300-hour Ayurveda Teacher Diploma.') }}</p>
            <p>{{ $t('Graduates will enjoy various benefits and continuous support from the organization, becoming certified Ayurveda teachers with World Yoga Alliance, having your own public profile page listed on our official website, where you can advertise your classes, courses or events.') }} {{ $t('Please check our ') }} <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards here.') }}</router-link></p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('After passed the 200 Hour Level 1.') }}</p>
            <p>{{ $t('Duration: Total 30 – 45 days regular training. However, it depends on the Schools or course timetable please check with school.') }}</p>
            <p><strong>{{ $t(' Training Standards for 300 Hours Ayurveda TTC Providers:') }}</strong></p>
            <ul><li>{{ $t('Ayurveda Practical (total 172 hour with 138 contact hour) ') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('The Fundamental Principles of Ayurveda') }}</b-td>
                  <b-td >{{ $t('51 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Self Healing Methodology') }}</b-td>
                  <b-td >{{ $t('47 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Business Plan:') }}</b-td>
                  <b-td >{{ $t('13 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Treating Disease with Ayurveda and Lifestyle') }}</b-td>
                  <b-td >{{ $t('11 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Practical Teaching & Workshop') }}</b-td>
                  <b-td >{{ $t('16 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Ayurveda Theoretical (total 128 hour with 87 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('Ayurveda Energy Systems') }}</b-td>
                  <b-td >{{ $t('33 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Theory of Diet & Optimal Health etc.') }}</b-td>
                  <b-td >{{ $t('28 Hours') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                  <b-td >{{ $t('26 Hours') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 75 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of Ayurveda theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
          <div id="400-hours-training-registration" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('400 Hours training level 2:') }}</h4>
            <p>{{ $t('WYA 400 Hours Ayurveda teacher training framework registration carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga and Ayurveda community. We set all of the following health and safety standards in order to provide the best experience for your students.') }}</p>
            <p>{{ $t('This 400 Hours Ayurveda Teacher Training is a Level 2 that follows further the syllabus and structure of the 250 Hours Ayurveda TTC level 1. This level is designed for all those who have already completed the 250 Hours Ayurveda Teacher Training and would like to deepen and broaden their knowledge, becoming professional Ayurveda teachers with the ability to become WYA Certified Ayurveda Trainers. If applicant haven’t graduated from a WYA 250 Hours Ayurveda TTC School, need to pass the orientation test to ensure that applicant have sufficient basic skills and knowledge to undertake further advanced course and continue the program. We recommend all WYA registrar and certified schools to require applicants of the 250 Hour Ayurveda TTC to pass the orientation test, specifically if the applicant has not previously completed a 250 Hour TTC through WYA.') }}</p>
            <p>{{ $t('All those who already have the 250 Hours level one Ayurveda TTC WYA Certificate with valid teacher ID, upon completion, they will be able to receive a 650 Hours Ayurveda Teacher Training Diploma with World Yoga Alliance. If student had done 250-hour course with non WYA School, then school can provide only 400-hour Ayurveda Teacher Diploma.') }}</p>
            <p>{{ $t('Graduates will enjoy various benefits and continuous support from the organization, becoming certified Ayurveda teachers with World Yoga Alliance, having your own public profile page listed on our official website, where you can advertise your classes, courses or events.') }} {{ $t('Please check our ') }} <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards here.') }}</router-link></p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('After passed the 250 Hour Level 1.') }}</p>
            <p>{{ $t('Duration: Total 38 – 50 days regular training. However, it depends on the Schools or course timetable please check with school.') }}</p>
            <p><strong>{{ $t('Training Standards for 400 Hours Ayurveda TTC Providers:') }}</strong></p>
            <ul><li>{{ $t('Ayurveda Practical (total 222 hour with 172 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('The Fundamental Principles of Ayurveda') }}</b-td>
                  <b-td >{{ $t('65 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Self Healing Methodology') }}</b-td>
                  <b-td >{{ $t('52 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Business Plan:') }}</b-td>
                  <b-td >{{ $t('18 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Treating Disease with Ayurveda and Lifestyle') }}</b-td>
                  <b-td >{{ $t('16 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Practical Teaching & Workshop') }}</b-td>
                  <b-td >{{ $t('21 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Theoretical (total 178 hour with 128 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('Ayurveda Energy Systems') }}</b-td>
                  <b-td >{{ $t('48 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Theory of Diet & Optimal Health etc.') }}</b-td>
                  <b-td >{{ $t('45 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                  <b-td >{{ $t('35 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 100 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of Ayurveda theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
          <div id="500-hours-training-registration" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('500 Hours training level 3:') }}</h4>
            <p>{{ $t('WYA 500 Hours Ayurveda teacher training framework registration carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga and Ayurveda community. We set all of the following health and safety standards in order to provide the best experience for your students.') }}</p>
            <p>{{ $t('This 500 Hours Ayurveda Teacher Training is a Level 3 that follows further the syllabus and structure of the 300 Hours Ayurveda TTC level 2. This level is designed for all those who have already completed the 300 Hours Ayurveda Teacher Training and would like to deepen and broaden their knowledge, becoming professional Ayurveda teachers with the ability to become WYA Certified Ayurveda Trainers. If applicant haven’t graduated from a WYA 300 Hours Ayurveda TTC School, need to pass the orientation test to ensure that applicant have sufficient basic skills and knowledge to undertake further advanced course and continue the program. We recommend all WYA registrar and certified schools to require applicants of the 300 Hour Ayurveda TTC to pass the orientation test, specifically if the applicant has not previously completed a 300 Hour TTC through WYA.') }}</p>
            <p>{{ $t('All those who already have the 300 Hours level one Ayurveda TTC WYA Certificate with valid teacher ID, upon completion, they will be able to receive a 800 Hours Ayurveda Teacher Training Diploma with World Yoga Alliance. If student had done 300-hour course with non WYA School, then school can provide only 500-hour Ayurveda Teacher Diploma.') }}</p>
            <p>{{ $t('Graduates will enjoy various benefits and continuous support from the organization, becoming certified Ayurveda teachers with World Yoga Alliance, having your own public profile page listed on our official website, where you can advertise your classes, courses or events.') }} {{ $t('Please check our ') }} <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards here.') }}</router-link></p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('After passed the 300 Hour Level 2.') }}</p>
            <p>{{ $t('Duration: Total 60 – 80 days regular training. However, it depends on the Schools or course timetable please check with school.') }}</p>
            <p><strong>{{ $t(' Training Standards for 500 Hours Ayurveda TTC Providers:') }}</strong></p>
            <ul><li>{{ $t('Ayurveda Practical (total 283 hour with 217 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('The Fundamental Principles of Ayurveda') }}</b-td>
                  <b-td >{{ $t('108 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Self Healing Methodology') }}</b-td>
                  <b-td >{{ $t('52 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Business Plan:') }}</b-td>
                  <b-td >{{ $t('30 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Treating Disease with Ayurveda and Lifestyle') }}</b-td>
                  <b-td >{{ $t('14 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Practical Teaching & Workshop') }}</b-td>
                  <b-td >{{ $t('13 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Theoretical (total 217 hour with 151 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('TAyurveda Energy Systems') }}</b-td>
                  <b-td >{{ $t('73 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Theory of Diet & Optimal Health etc.') }}</b-td>
                  <b-td >{{ $t('40 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                  <b-td >{{ $t('38 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 132 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of Ayurveda theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
          <div id="600-hours-training-registration" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('600 Hours training level 3:') }}</h4>
            <p>{{ $t('WYA 600 Hours Ayurveda teacher training framework registration carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga and Ayurveda community. We set all of the following health and safety standards in order to provide the best experience for your students.') }}</p>
            <p>{{ $t('This 600 Hours Ayurveda Teacher Training is a Level 3 that follows further the syllabus and structure of the 400 Hours Ayurveda TTC level 2. This level is designed for all those who have already completed the 400 Hours Ayurveda Teacher Training and would like to deepen and broaden their knowledge, becoming professional Ayurveda teachers with the ability to become WYA Certified Ayurveda Trainers. If applicant haven’t graduated from a WYA 400 Hours Ayurveda TTC School, need to pass the orientation test to ensure that applicant have sufficient basic skills and knowledge to undertake further advanced course and continue the program. We recommend all WYA registrar and certified schools to require applicants of the 400 Hour Ayurveda TTC to pass the orientation test, specifically if the applicant has not previously completed a 400 Hour Ayurveda TTC through WYA.') }}</p>
            <p>{{ $t('All those who already have the 400 Hours level one Ayurveda TTC WYA Certificate with valid teacher ID, upon completion, they will be able to receive a 1,000 Hours Ayurveda Teacher Training Diploma with World Yoga Alliance. If student had done 400-hour course with non WYA School, then school can provide only 600-hour Ayurveda Teacher Diploma.') }}</p>
            <p>{{ $t('Graduates will enjoy various benefits and continuous support from the organization, becoming certified Ayurveda teachers with World Yoga Alliance, having your own public profile page listed on our official website, where you can advertise your classes, courses or events.') }} {{ $t('Please check our ') }}  <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards here.') }}</router-link></p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('After passed the 400 Hour Level 2.') }}</p>
            <p>{{ $t('Duration: Total 90 – 180 days regular training. However, it depends on the Schools or course timetable please check with school.') }}</p>
            <p><strong>{{ $t('Training Standards for 600 Hours Ayurveda TTC Providers:') }}</strong></p>
            <ul><li>{{ $t('Ayurveda Practical (total 333 hour with 258 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('The Fundamental Principles of Ayurveda') }}</b-td>
                  <b-td >{{ $t('118 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Self Healing Methodology') }}</b-td>
                  <b-td >{{ $t('62 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Business Plan:') }}</b-td>
                  <b-td >{{ $t('40 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Treating Disease with Ayurveda and Lifestyle') }}</b-td>
                  <b-td >{{ $t('24 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Practical Teaching & Workshop') }}</b-td>
                  <b-td >{{ $t('14 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Ayurveda Theoretical (total 267 hour with 192 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('Ayurveda Energy Systems') }}</b-td>
                  <b-td >{{ $t('88 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Theory of Diet & Optimal Health etc.') }}</b-td>
                  <b-td >{{ $t('53 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                  <b-td >{{ $t('51 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 150 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of Ayurveda theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
          <div id="1000-hours-training-registration" class="mt-5">
            <h4 class="font-weight-bold text-center mb-5">{{ $t('1,000 Hours training level 4:') }}</h4>
            <p>{{ $t('WYA 1,000 Hours Ayurveda teacher training framework registration carries out the highest quality and helps you to make your course invaluable and recognized worldwide by the yoga and Ayurveda community. We set all of the following health and safety standards in order to provide the best experience for your students. ') }}</p>
            <p>{{ $t('This 1,000 Hours Ayurveda Teacher Training is a Level 4 that follows further the syllabus and structure of the 500 Hours or 600 Hours Ayurveda TTC as level 3. This level is designed for all those who have already completed the level 3 Ayurveda Teacher Training and would like to deepen and broaden their knowledge, becoming professional Ayurveda teachers with the ability to become WYA Certified Ayurveda Trainers.') }}</p>
            <p>{{ $t('If applicant haven’t graduated from a WYA Level 3 Ayurveda TTC School, need to pass the orientation test to ensure that applicant have sufficient basic skills and knowledge to undertake further advanced course and continue the program. We recommend all WYA registrar and certified schools to require applicants of the Ayurveda TTC to pass the orientation test, specifically if the applicant has not previously completed a level 3 TTC through WYA.') }}</p>
            <p>{{ $t('All those who already have the Level 3 Ayurveda TTC WYA Certificate with valid teacher ID, upon completion, they will able to receive an Ayurveda Teacher Training Diploma with combined hours such as;') }}</p>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('If you have done WYA 500-hour level 3 will received 1,500 Hour Teacher Training Diploma.') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('If you have done WYA 600-hour level 3 will received 1,600 Hour Teacher Training Diploma.') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('If you had done level 3 course with non WYA School, then you will get only 1,000-hour Ayurveda Teacher Diploma.') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <p>{{ $t('Graduates will enjoy various benefits and continuous support from the organization, becoming certified Ayurveda teachers with World Yoga Alliance, having your own public profile page listed on our official website, where you can advertise your classes, courses or events.') }} {{ $t('Please check our ') }} <router-link :to="{ name: 'EYEP' }">{{ $t('EYEP Standards here.') }}</router-link></p>
            <p><strong><u>{{ $t('Eligibility to Sign up') }}</u>:</strong> {{ $t('After passed the Level 3 as 500 Hours or 600 Hours Yoga Teacher Training.') }}</p>
            <p>{{ $t('Duration: Total 140 – 365 days regular training. However, it depends on the Schools or course timetable please check with school.') }}</p>
            <p><strong>{{ $t('Training Standards for 1,000 Hours Ayurveda TTC Providers:') }}</strong></p>
            <ul><li>{{ $t('Ayurveda Practical (total 533 hour with 408 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('The Fundamental Principles of Ayurveda') }}</b-td>
                  <b-td >{{ $t('148 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Self Healing Methodology') }}</b-td>
                  <b-td >{{ $t('92 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Business Plan:') }}</b-td>
                  <b-td >{{ $t('70 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Treating Disease with Ayurveda and Lifestyle') }}</b-td>
                  <b-td >{{ $t('54 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Practical Teaching & Workshop') }}</b-td>
                  <b-td >{{ $t('44 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Theoretical (total 467 hour with 342 contact hour)') }}</li></ul>
            <b-table-simple caption-top responsive bordered class="table-default mt-3">
              <b-thead>
                <b-tr>
                  <b-th class="w-75" variant="light" >{{ $t('Topics') }}</b-th>
                  <b-th variant="light" >{{ $t('Total Hours')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td >{{ $t('Ayurveda Energy Systems') }}</b-td>
                  <b-td >{{ $t('138 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Theory of Diet & Optimal Health etc.') }}</b-td>
                  <b-td >{{ $t('103 Hour') }}</b-td>
                </b-tr>
                <b-tr>
                  <b-td >{{ $t('Anatomy and Physiology') }}</b-td>
                  <b-td >{{ $t('101 Hour') }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <ul><li>{{ $t('Homework (total 250 non-contact hours)') }}</li></ul>
            <p>{{ $t('Homework includes a personal practice of Ayurveda theoretical knowledge and practical techniques, journaling, and specific essay assignments including communication skills.') }}</p>
            <p>{{ $t('If you have any questions about the training standards, please feel free to') }}  <router-link :to="{ name:'contact' }">{{ $t('contact us.') }}</router-link></p>
            <div class="col text-center"><b-button pill :to="{name: 'applyType', query: { type: 'RSY' }}" class="ml-3 mt-2 px-5 my-4 font-weight-bold btn-danger-light">{{ $t('Apply now') }}</b-button></div>
          </div>
        </div>
      </b-container>
  </div>
</template>

<script>
export default {
  name: 'ChildrenYogaSchool',
  title: ' | Children\'s Yoga School',
  data () {
    return {
      loading: false
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    scrollToElement (id) {
      const element = document.getElementById(id)
      const elementOffset = element.offsetTop - 80
      console.log('elementOffset', elementOffset)
      window.scroll({ top: elementOffset, behavior: 'smooth' })
    },
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  #school-standards {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
  .levels-table td:hover,
  .introduction li:hover,
  .introduction strong:hover {
    cursor:pointer;
  }
</style>
